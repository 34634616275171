@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  margin: 0;
  padding: 0;
  background-color: #0E0F16;
}
body {
  font-family: sans-serif;
  background-image: url(../public/felt.png), -webkit-radial-gradient(50% 40%, circle farthest-corner, #e774954c, #0E0F16);
  background-size: 180px 180px, auto;
  background-attachment: fixed;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  margin: 0;
  padding: 0;
}
#app {
  contain: layout style;
  overflow: visible;
}

.roulette-wheel {
  float: right;
  width: 380px;
  height: 380px;
  border-radius: 100%;
  background: url(../public/r1.png);
  background-size: 380px 380px;
  shape-outside: circle(190px);
  margin: 0 0 1em 1em;
  box-shadow: 2px 10px 30px rgba(0, 0, 0, 0.4);
  position: relative;
  touch-action: none;
  overflow: visible;
}
@media screen and (max-width: 2640px) {
  .roulette-wheel {
    float: none;
    margin: 1em auto;
  }
}
@media screen and (max-width: 375px) {
  body {
    padding: 0 20px;
  }
  .roulette-wheel {
    float: none;
    left: 30px;
  }
}
.roulette-wheel .layer-2, .roulette-wheel .layer-3, .roulette-wheel .layer-4, .roulette-wheel .layer-5, .ball-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 380px 380px;
}
.roulette-wheel .layer-2, .roulette-wheel .layer-4, .ball-container {
  will-change: transform;
}
.roulette-wheel .layer-2 {
  background-image: url(../public/r2.png);
}
.roulette-wheel .layer-3 {
  background-image: url(../public/r3.png);
}
.roulette-wheel .layer-4 {
  background-image: url(../public/r4.png);
}
.roulette-wheel .layer-5 {
  background-image: url(../public/r5.png);
}
.roulette-wheel svg {
  position: absolute;
  top: 0;
  left: 0;
}
.roulette-wheel circle {
  cursor: pointer;
  fill: transparent;
}
.roulette-wheel .ball {
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background: #fff radial-gradient(circle at 5px 5px, #fff, #444);
  box-shadow: 1px 1px 4px #000;
  transform: translateY(-116px);
  top: 50%;
  left: 50%;
  margin: -7px;
  will-change: transform;
}


.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle {
  border-radius: 50%;
}

.flipper-container {
  perspective: 1000px;
  -webkit-perspective: 1000px;
}
.flipper {
  display: inline-block;
  position: relative;
  width: 96px;
  height: 128px;
  transition: all 2s cubic-bezier(0.24, 1.65, 0.915, 0.835);
  transform-style: preserve-3d;
  font-family: monospace;
  user-select: none;
}
.flipper.flip {
  transform: rotateX(90deg);
}
.front-face, .back-face {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 2px;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 76px;
}
.front-face {
  background-color: #fc216100;
  color: #fff;
  text-shadow: 0 1px 1px #b50236;
  transform: rotateX(0deg) translateZ(64px);
}
.back-face {
  color: #dca041;
  transform: rotateX(-90deg) translateZ(64px);
  outline: solid 2px;
}
.flip.red .back-face {
  background-color: #c52703;
}
.flip.black .back-face {
  background-color: #242324;
}
.flip.green .back-face {
  background-color: #436410;
}
.result {
  max-width: 500px;
  margin: auto;
}
.result .flipper {
  margin-right: 20px;
  margin-bottom: 20px;
}
.stickyNav {
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 9999 !important;
}

.nunito {
  font-family: 'Nunito', sans-serif;
}

.monts {
  font-family: 'Montserrat', sans-serif;
}

.monospace {
  font-family: monospace;
}

.dmsans {
  font-family: "DM Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}